@font-face {
font-family: '__nussbaum_ea3d16';
src: url(/_next/static/media/e7491fc7b72dd958-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__nussbaum_ea3d16';
src: url(/_next/static/media/455857b5f9670a6b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__nussbaum_Fallback_ea3d16';src: local("Arial");ascent-override: 100.38%;descent-override: 25.02%;line-gap-override: 0.00%;size-adjust: 97.53%
}.__className_ea3d16 {font-family: '__nussbaum_ea3d16', '__nussbaum_Fallback_ea3d16'
}.__variable_ea3d16 {--font-client-nussbaum: '__nussbaum_ea3d16', '__nussbaum_Fallback_ea3d16'
}

@font-face {
font-family: '__stadtwerkeHanau_35dfa4';
src: url(/_next/static/media/6c91a0e0757ba3df-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__stadtwerkeHanau_35dfa4';
src: url(/_next/static/media/472650e07c64d30a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__stadtwerkeHanau_Fallback_35dfa4';src: local("Arial");ascent-override: 85.50%;descent-override: 20.38%;line-gap-override: 0.00%;size-adjust: 112.87%
}.__className_35dfa4 {font-family: '__stadtwerkeHanau_35dfa4', '__stadtwerkeHanau_Fallback_35dfa4'
}.__variable_35dfa4 {--font-client-stadtwerke-hanau: '__stadtwerkeHanau_35dfa4', '__stadtwerkeHanau_Fallback_35dfa4'
}

